import DashboardActiveIcon from "../assets/Sidebar/deactive/dashboard.svg";
import Activedashboard from "../assets/Sidebar/active/Dashboard.svg";
import LeadsActiveIcon from "../assets/Sidebar/deactive/leads.svg";
import activelead from "../assets/Sidebar/active/Lead.svg";
import BidsActiveIcon from "../assets/Sidebar/deactive/bids.svg";
import activebid from "../assets/Sidebar/active/Bid.svg";
import UserActiveIcon from "../assets/Sidebar/deactive/user.svg";
import activeuser from "../assets/Sidebar/active/User.svg";
import OnboardActiveIcon from "../assets/Sidebar/deactive/onboard.svg";
import activehand from "../assets/Sidebar/active/Hand.svg";
import deactivesetting from "../assets/Sidebar/deactive/settings.svg";
import activesetting from "../assets/Sidebar/active/activesetting.svg";
import dalluser from "../assets/Sidebar/deactive/d-alluser.svg";
import Aalluser from "../assets/Sidebar/active/a-alluser.svg";
import Anewsletter from "../assets/Sidebar/active/Notification.svg";
import Dnewsletter from "../assets/Sidebar/deactive/Dnewsletter.svg";
import Avendor from "../assets/Sidebar/active/Document.svg";
import { NavConstants } from "../navigations/NavConstants";
import Accounts from "../assets/FinanceAdmin/Accounts.svg";
import Accounts2 from "../assets/FinanceAdmin/Accounts2.svg";
import Wallet from "../assets/FinanceAdmin/Wallet.svg";
import Wallet2 from "../assets/FinanceAdmin/Wallet2.svg";
import Notification from "../assets/FinanceAdmin/Notification.svg";
import Notification2 from "../assets/FinanceAdmin/Notification2.svg";
import Document from "../assets/FinanceAdmin/Document.svg";
import Document2 from "../assets/FinanceAdmin/Document2.svg";

const SalesAdmin = [
  {
    label: "Dashboard",
    navigation: NavConstants.dashboard,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Leads",
    navigation: NavConstants.leads,
    activeIcons: activelead,
    deactiveIcons: LeadsActiveIcon,
  },
  {
    label: "DM Leads",
    navigation: NavConstants.dmleads,
    activeIcons: activelead,
    deactiveIcons: LeadsActiveIcon,
  },
  // {
  //   label: "Supplier Bids",
  //   navigation: NavConstants.BidsSupplier,
  //   activeIcons: activebid,
  //   deactiveIcons: BidsActiveIcon,
  // },
  // {
  //   label: "Service Bids",
  //   navigation: NavConstants.ServiceBid,
  //   activeIcons: activebid,
  //   deactiveIcons: BidsActiveIcon,
  // },
  // {
  //   label: "Project Update",
  //   navigation: NavConstants.ProjectUpdate,
  //   activeIcons: activeuser,
  //   deactiveIcons: UserActiveIcon,
  // },
  {
    label: "All Projects",
    navigation: NavConstants.userFeedback,
    activeIcons: activeuser,
    deactiveIcons: UserActiveIcon,
  },
  {
    label: "Sales Employee",
    navigation: NavConstants.salesEmployee,
    activeIcons: activeuser,
    deactiveIcons: UserActiveIcon,
  },
  // {
  //   label: "Onboarding",
  //   navigation: NavConstants.vendoronboarding,
  //   activeIcons: activehand,
  //   deactiveIcons: OnboardActiveIcon,
  // },
];

const SystemAdmin = [
  {
    label: "Dashboard",
    navigation: NavConstants.systemAdminDashboard,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Setting",
    navigation: NavConstants.Category,
    activeIcons: activesetting,
    deactiveIcons: deactivesetting,
  },
  {
    label: "All Projects",
    navigation: NavConstants.AllUser,
    activeIcons: Aalluser,
    deactiveIcons: dalluser,
  },
  // {
  //   label: "Newsletters",
  //   navigation: NavConstants.Newsletter,
  //   activeIcons: Anewsletter,
  //   deactiveIcons: Dnewsletter,
  // },
  {
    label: "All Vendors",
    navigation: NavConstants.AllVendorSales,
    activeIcons: Avendor,
    deactiveIcons: DashboardActiveIcon,
  },
  // {
  //   label: "Projects",
  //   navigation: "",
  //   activeIcons: Activedashboard,
  //   deactiveIcons: DashboardActiveIcon,
  // },
  {
    label: "Materials",
    navigation: NavConstants.MaterialInventory,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  // {
  //   label: "Sales Employee",
  //   navigation: NavConstants.AdminSalesEmployee,
  //   activeIcons: Activedashboard,
  //   deactiveIcons: DashboardActiveIcon,
  // },
  {
    label: "Admin Project Manager",
    navigation: NavConstants.AdminProjectManager,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Admin Finance",
    navigation: NavConstants.AdminFinanace,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Admin Sales",
    navigation: NavConstants.ManageAdminSales,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Admin QA/QC",
    navigation: NavConstants.qaqc,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
];

const FinanceAdmin = [
  {
    label: "Dashboard",
    navigation: NavConstants.financeDashboard,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "Transactions",
    navigation: NavConstants.transaction,
    activeIcons: Wallet2,
    deactiveIcons: Wallet,
  },
  {
    label: "Accounts",
    navigation: NavConstants.accounts,
    activeIcons: Accounts2,
    deactiveIcons: Accounts,
  },
  // {
  //   label: "Notification",
  //   navigation: NavConstants.notification,
  //   activeIcons: Notification,
  //   deactiveIcons: Notification2,
  // },
  {
    label: "Project Payment",
    navigation: NavConstants.ProjectPayment,
    activeIcons: Document2,
    deactiveIcons: Document,
  },
  {
    label: "Referal Projects",
    navigation: NavConstants.referalProjects,
    activeIcons: Document2,
    deactiveIcons: Document,
  },
];

const Dm = [
  {
    label: "DM Leads",
    navigation: NavConstants.dmleads,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
];
const ProjectmanagerAdmin = [
  {
    label: "Dashboard",
    navigation: NavConstants.ProjectManagerDashboard,
    activeIcons: activehand,
    deactiveIcons: OnboardActiveIcon,
  },
  // {
  //   label: "Manage Project",
  //   navigation: NavConstants.ManageProject,
  //   activeIcons: Activedashboard,
  //   deactiveIcons: DashboardActiveIcon,
  // },
];

const QualityAdmin = [
  {
    label: "Dashboard",
    navigation: NavConstants.QaQcDashboard,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  {
    label: "My Projects",
    navigation: NavConstants.MyProjects,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
];

const salesEmployee = [
  // {
  //   label: "Dashboard",
  //   navigation: NavConstants.salesemployeedashboard,
  //   activeIcons: Activedashboard,
  //   deactiveIcons: DashboardActiveIcon,
  // },
  {
    label: "Project Updates",
    navigation: NavConstants.projectupdates,
    activeIcons: Activedashboard,
    deactiveIcons: DashboardActiveIcon,
  },
  // {
  //   label: "DM Leads",
  //   navigation: NavConstants.dmleads,
  //   activeIcons: Activedashboard,
  //   deactiveIcons: DashboardActiveIcon,
  // },
];

export {
  SalesAdmin,
  SystemAdmin,
  FinanceAdmin,
  ProjectmanagerAdmin,
  QualityAdmin,
  salesEmployee,
  Dm,
};
