import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import "./ProjectTeamModal.css";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { UserController } from "../../../../Redux/controllers/UserController";
import Utility from "../../../../Utility/Utility";
function ProjectTeamModal(props) {
  const [projectRole, setProjectRole] = useState("QAQC");
  const [projectData, setProjectData] = useState();
  const { token } = useSelector((state) => state.user);
  const [memberData, setMemberData] = useState({
    employeeId: "",
    subRole: ""
  });


  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setProjectRole(selectedValue);
  };

  const handleItemChange = (event) => {
    // console.log("Add member data Event is ++++", event)
    const selectedEmployeeId = event.target.value;
    const selectedEmployee = projectData?.data?.data?.subAdmins.find(
      (admin) => admin._id === selectedEmployeeId
    );

    if (selectedEmployee) {
      setMemberData({
        employeeId: selectedEmployee._id,
        subRole: selectedEmployee.subRole || projectRole
      });
    }
    console.log("Add member data ++++", memberData)
  };

  const fetchProjectTeam = async (role, token) => {
    try {
      let response = await UserController.ProjectTeamData(role, token);
      setProjectData(response);
    } catch (error) {
      console.log("Something went wrong while fetching modal data", error);
    }
  };

  useEffect(() => {
    if (props.show) {
      fetchProjectTeam(projectRole, token);
    }
  }, [props.show, projectRole, token]);

  const AddMemberData = async () => {
    try {
      const response = await UserController.AddMember(props.projectId, memberData, token)
      console.log("member added successfully", response)
      Utility.sToast("Membet updated successfully")
      props.RefreshProjectDetails()
      props.onHide()

    } catch (error) {
      console.error("Error adding member data:", error);
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Modal.Header closeButton id="project-team-modal-header-style">
          <Modal.Title
            className="contained-modal-title-vcenter"
            id="project-team-heading"
          >
            Project team
          </Modal.Title>
        </Modal.Header>
        <Container>
          <Form>
            <Row>
              <Col md={12} xs={12}>
                <Form.Group className="mb-3" >
                  <Form.Label id="project-team-form-label-style">
                    Select Manager
                  </Form.Label>
                  <Form.Select
                    autoFocus
                    id="project-team-placeholder-style"
                    onChange={handleSelectChange}
                    value={projectRole}
                  >
                    <option value="QAQC">QAQC</option>
                    <option value="SALES">SALES</option>
                    <option value="PROJECTMANAGER">PROJECTMANAGER</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <Form.Group className="mb-3" >
                  <Form.Label id="project-team-form-label-style">
                    Member Name
                  </Form.Label>
                  <Form.Select
                    autoFocus
                    id="project-team-placeholder-style"
                    onChange={handleItemChange}
                    value={memberData.employeeId}
                  >
                    <option value="">Add Member</option>
                    {projectData?.data?.data?.subAdmins?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {`${item?.firstName} ${item.lastName}`}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group
                  className="mb-3"

                >
                  <Form.Label id="project-team-form-label-style">
                    Job Role
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Member job role"
                    autoFocus
                    id="project-team-placeholder-style"
                    disabled
                    value={memberData.subRole || projectRole}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button variant="outline-primary" id="project-team-add-btn" onClick={AddMemberData}>
                  Add Member
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start", width: "100%" }}>
        {props.userData?.data?.data?.projectManager && <Row style={{ width: "100%", marginBottom: "10px" }}>
          <Col md={12} id="footer-content">
            <div>
              <div id="project-team-modal-name" style={{ color: "#1449F2" }}>
                {`${props.userData?.data?.data?.projectManager?.firstName} ${props.userData?.data?.data?.projectManager?.lastName}`}
              </div>
              <div id="project-team-modal-title">{props.userData?.data?.data?.projectManager?.subRole}</div>
            </div>
          </Col>
        </Row>}

        {props.userData?.data?.data?.qaQcSubAdmin && <Row style={{ width: "100%", marginBottom: "10px" }}>
          <Col md={12} id="footer-content">
            <div>
              <div id="project-team-modal-name">{`${props.userData?.data?.data?.qaQcSubAdmin?.firstName} ${props.userData?.data?.data?.qaQcSubAdmin?.lastName}`}</div>

              <div id="project-team-modal-title">{props.userData?.data?.data?.qaQcSubAdmin?.subRole}</div>
            </div>
            <span id="project-team-cross-btn">
              <RxCross1 />
            </span>
          </Col>
        </Row>}
        {props.userData?.data?.data?.salesPerson && <Row style={{ width: "100%", marginBottom: "10px" }}>
          <Col md={12} id="footer-content">
            <div>
              <div id="project-team-modal-name">{`${props.userData?.data?.data?.salesPerson?.firstName} ${props.userData?.data?.data?.salesPerson?.lastName}`}</div>

              <div id="project-team-modal-title">{props.userData?.data?.data?.salesPerson?.subRole}</div>
            </div>
            <span id="project-team-cross-btn">
              <RxCross1 />
            </span>
          </Col>
        </Row>}

      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(ProjectTeamModal);
