import { Container, Col, Row, Dropdown, Spinner } from "react-bootstrap";
import LoginLogo from "../../assets/LoginPage/Mask group.png";
import cornerImage from "../../assets/LoginPage/Bricxy5 2.png";
import "./LoginPage.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import ProjectTeamModal from "../../All_Panels/SystemAdmin_Panel/Component/ProjectTeamModal/ProjectTeamModal";
import { useDispatch } from "react-redux";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { login, setChooseUserType } from "../../Redux/slice/userSlice";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../Utility/Utility";

function LoginPage() {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const handleSelect = (eventKey) => {
    dispatch(setChooseUserType(eventKey));
    setSelectedRole(eventKey);
  };

  const validate = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    return newErrors;
  };

  const handleLogin = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      const data = { email, password };
      dispatch(login(data))
        .then(unwrapResult)
        .then((res) => {
          console.log("here is login response++++", res);
          setLoading(false);
          Utility.sToast("Login Sucessful");
        })
        .catch((error) => {
          console.log(error, "error.........");
          Utility.eToast(error?.response?.data?.message || error?.message);
          setLoading(false);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email, password]);

  return (
    <Container fluid className="login-page">
      <Row>
        <Col
          md={{ order: "first", span: 4 }}
          xs={{ order: "second", span: 12 }}
          className="login-logo-col"
        >
          <div style={{ marginTop: "-3rem" }}>
            <div>
              <img src={LoginLogo} className="login-logo" alt="Login Logo" />
            </div>
            <div className="first-line">Welcome back Admin</div>
            <div className="second-line">
              Admin's role is grand, managing every strand, our login panel
              needs a careful hand.
            </div>
          </div>
        </Col>
        <Col
          md={{ order: "second", span: 8 }}
          xs={{ order: "first", span: 12 }}
          className="login-form-col"
        >
          <img
            src={cornerImage}
            className="corner-image"
            alt="Corner Decoration"
          />
          <div className="form-div">
            <Form>
              <div className="login-form-heading">Admin Login</div>
              <p className="login-form-para">
                Verify yourself as a Bricxy admin by entering your email ID and
                password
              </p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  className="placeholder-style"
                  placeholder="e.g. johndoe@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="form-label">Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className="placeholder-style"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="eye-spannn"
                    // style={{
                    //   position: "absolute",
                    //   right: "10px",
                    //   top: "50%",
                    //   transform: "translateY(-50%)",
                    //   cursor: "pointer",
                    // }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEye /> : <FiEyeOff />}
                  </span>
                </div>
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
              </Form.Group>
              <Button
                className="login-button"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Login"}
              </Button>
              {/* <div className="forgot-password-div">
                Forgot password? <span className="reset-link">Reset</span>
              </div> */}
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdownnClass"
                  >
                    {selectedRole || "Choose User Type"}
                    <MdOutlineKeyboardArrowDown className="drop-down-icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="system">
                      System Admin
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="sales">Sales Admin</Dropdown.Item>
                    <Dropdown.Item eventKey="finance">
                      Finance Admin
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </Form>
          </div>
        </Col>
      </Row>
      <ProjectTeamModal show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
}

export default React.memo(LoginPage);
