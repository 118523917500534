import React, { useEffect, useState, useMemo } from "react";
import "./DashboardChart.css";
import Form from "react-bootstrap/Form";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { formatCurrency } from "../../constants/formatCurrency";

function DashboardChart(props) {
  const {
    chartData = [],
    headerName = "N/A",
    color = "#3751A3",
    handleSelectedyear,
    showRupeesSymbol = false,
  } = props || {};

  const currentYear = moment().year();
  const [year, setYear] = useState(currentYear);
  const [yearList, setYearList] = useState([]);

  console.log("Selected Year: ", year); // Debugging

  const maxValue = useMemo(
    () => Math.max(...chartData.map((data) => data[headerName] || 0)),
    [chartData, headerName]
  );

  const isAllDataZero = maxValue === 0;
  const tickInterval = isAllDataZero ? 1000 : Math.ceil(maxValue / 5);

  const ticks = useMemo(
    () => Array.from({ length: 6 }, (_, i) => i * tickInterval),
    [tickInterval]
  );

  useEffect(() => {
    const startYear = 2019;
    const currentYear = new Date().getFullYear();
    getYearList(startYear, currentYear);
  }, []);

  const handleChange = (value) => {
    const selectedYear = Number(value); // Convert the string value to a number
    console.log("Selected Year from dropdown: ", selectedYear);
    setYear(selectedYear);
    handleSelectedyear(selectedYear); // Pass the number value to the handler
  };

  // Show All year list in dropdown menu
  function getYearList(startYear, endYear) {
    const years = Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => startYear + index
    );
    setYearList(years);
  }

  const tickFormatter = (tick) => {
    return `${showRupeesSymbol ? "₹" : ""}${formatCurrency(tick)}`;
  };

  return (
    <div id="graph-chart-div">
      <Row>
        <Col md={6} lg={8} sm={8}>
          <span className="system-admin-chart-left-header">{headerName}</span>
        </Col>
        <Col>
          <Form.Select
            id="system-admin-chart-select"
            value={year}
            onChange={(e) => handleChange(e.target.value)} // Pass the selected year
          >
            <option>Year</option>
            {yearList?.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <ResponsiveContainer width="100%" height={370}>
        <BarChart
          barSize={15}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="10 10" />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis
            tickFormatter={tickFormatter}
            ticks={ticks} // Dynamic ticks based on max value
            domain={[0, maxValue]} // Dynamic domain based on max value
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Legend
            wrapperStyle={{
              bottom: 0,
              left: 45,
              paddingTop: 20,
            }}
          />
          <Bar
            dataKey={headerName}
            fill={color}
            radius={[10, 10, 10, 10]}
            legendType="rounded"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(DashboardChart);
