// src/redux/slices/userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";

const initialState = {
  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  token: null,
  userInfo: {},
  userType: "", // "system" || "sales" || "finance" || "projectmanager" || "quality",
};

export const login = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.login(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const getOurteam = createAsyncThunk(
//   "getOurteam",
//   async (token,{ rejectWithValue }) => {
//     try {
//       const response = await UserController.getOurteam(token);
//       console.log("Response:", response);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Corrected from "reducer" to "reducers"
    setChooseUserType(state, action) {
      state.userType = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    logout(state) {
      // Clear user-related state
      state.token = null;
      state.userInfo = {};
      state.userType = "system";
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload?.data?.user?.role === "ADMIN") {
          state.userType = "system";
        }
        if (action.payload?.data?.user?.subRole === "SALES") {
          state.userType = "sales";
        }
        if (action.payload?.data?.user?.subRole === "FINANCE") {
          state.userType = "finance";
        }
        if (action.payload?.data?.user?.subRole === "PROJECTMANAGER") {
          state.userType = "projectmanager";
        }
        if (action.payload?.data?.user?.subRole === "QAQC") {
          state.userType = "quality";
        }
        if (action.payload?.data?.user?.subRole === "EMPLOYEE") {
          state.userType = "employee";
        }
        if (action.payload?.data?.user?.subRole === "DM") {
          state.userType = "dm";
        }
        state.token = action.payload.data.token;
        state.userInfo = action?.payload?.data.user;
        state.isLoading = false;
        state.isSuccess = true;
        console.log(action.payload.data, "api response ................");
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });

    // builder
    //   .addCase(getOurteam.pending, (state) => {
    //     state.isLoading = true;
    //     state.isError = false;
    //     state.isSuccess = false;
    //   })
    //   .addCase(getOurteam.fulfilled, (state, action) => {
    //     console.log(
    //       "Get Ourteam action payload:................",
    //       action.payload
    //     );
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //   })
    //   .addCase(getOurteam.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.error = action.payload;
    //   });
  },
});

// Export any action creators
export const { logout, setChooseUserType, setToken } = userSlice.actions;

export default userSlice.reducer;
