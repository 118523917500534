import { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
// import RootRouter from "./navigations/RootRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import { Loader } from "./GlobalComponent";

const RootRouter = lazy(() => import("./navigations/RootRouter"));

function App() {
  return (
    <>
      <Suspense
        fallback={
            <Loader />
        }
      >
        <RootRouter />
      </Suspense>
      <Toaster />
    </>
  );
}

export default App;
