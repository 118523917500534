import { toast } from "react-hot-toast";

const sToast = (msg) => {
  toast.success(msg, {
    // position: "top-left  top-center  top-right  bottom-left  bottom-center  bottom-right"
    position: "top-right",
    reverseOrder: false,
    duration: 2000,
  });
};

const eToast = (msg) => {
  toast.error(msg, {
    // position: "top-left  top-center  top-right  bottom-left  bottom-center  bottom-right"
    position: "top-right",
    reverseOrder: false,
    duration: 2000,
  });
};

function formatDateTime(isoString) {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  // const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} (${hours}:${minutes})`;
}

const Utility = {
  eToast,
  sToast,
  formatDateTime
};

export default Utility;
