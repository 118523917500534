import { Modal, Spinner } from "react-bootstrap";
import "./Loader.css";
function Loader(props) {
  return (
    <>
      <Modal
        {...props}
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        className="loader1"
      >
        <div>
          <Spinner animation="border" variant="light" id="loader2" />
        </div>
        <div>Loading...</div>
      </Modal>
    </>
  );
}

export default Loader;
