import React, { useState } from "react";
import { Col, Container, Image, Offcanvas, Row } from "react-bootstrap";
import "./Header.css";
import logoimg from "../../assets/Header/profile.svg";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LuMenuSquare } from "react-icons/lu";
import {
  SalesAdmin,
  SystemAdmin,
  FinanceAdmin,
  ProjectmanagerAdmin,
  QualityAdmin,
  salesEmployee,
  Dm,
} from "../../constants/UserType";
import Logo from "../../assets/Sidebar/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/slice/userSlice";
import Logout from "../../assets/Logout.svg";

const MobileViewHeaderComp = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const [show, setShow] = useState(false);
  const { userType } = useSelector((state) => state.user);

  let currentUserType;
  switch (userType) {
    case "sales":
      currentUserType = SalesAdmin;
      break;
    case "system":
      currentUserType = SystemAdmin;
      break;
    case "finance":
      currentUserType = FinanceAdmin;
      break;
    case "projectmanager":
      currentUserType = ProjectmanagerAdmin;
      break;
    case "employee":
      currentUserType = salesEmployee;
      break;
    case "dm":
      currentUserType = Dm;
      break;
    default:
      currentUserType = QualityAdmin;
  }

  let capitalizedText = userType?.charAt(0).toUpperCase() + userType?.slice(1);

  const handleClose = () => {
    setShow(false);
    window.scrollTo({
      top: 0,
      right: 0,
      behavior: "smooth",
    });
  };

  const handleShow = () => setShow(true);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(logout());
    }
  };

  return (
    <>
      <div id="mobile-profile">
        <div onClick={handleShow}>
          <LuMenuSquare size={35} />
        </div>
        <div id="mobile-profile-icon-div">
          {/* <Image src={logoimg} className="globalLogo" /> */}
          <div id="profile-FName-div">{capitalizedText?.slice(0, 1)}</div>
          <span className="adminnnSystemm12"> {capitalizedText} admin</span>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={props.placement}
        style={{ width: "90%" }}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/" onClick={handleClose}>
              <img src={Logo} alt="Logo" id="mobile-logo" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div id="offcanvasHeader3">
            <Row>
              {currentUserType.map((item, i) => (
                <Col xs={12} md={12} key={i}>
                  <div
                    id={
                      item.navigation === currentPath
                        ? "active-card-container"
                        : "deactive-card-container"
                    }
                    onClick={() => {
                      handleClose();
                      navigate(item.navigation);
                    }}
                  >
                    <img
                      src={
                        item.navigation === currentPath
                          ? item.activeIcons
                          : item.deactiveIcons
                      }
                      id="card-logo"
                    />
                    <div
                      id={
                        item.navigation === currentPath
                          ? "active-card-text"
                          : "card-text"
                      }
                    >
                      {item.label}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div id="mobile-logout" onClick={handleLogout}>
              <span>
                <img id="logoutImage" src={Logout} alt="Logout" />
              </span>
              <h6>Log Out</h6>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

function Header() {
  const { userType = "" } = useSelector((state) => state.user);
  let capitalizedText = userType?.charAt(0).toUpperCase() + userType?.slice(1);
  return (
    <Container fluid className="global-fluidd">
      <div id="large-screen-header">
        <Row>
          <Col className="welcome-bakk" md={6}>
            Welcome back{" "}
            <span className="adminnn"> {" " + capitalizedText} Admin !</span>
          </Col>
          <Col className="adminnnSystemm" md={6}>
            {/* <Image
              src={logoimg}
              className="globalLogo"
            // onClick={() => window.location.assign(NavConstants.profileEdit)}
            /> */}
            <div id="profile-FName-div">{capitalizedText?.slice(0, 1)}</div>
            {/* <span className="adminnnSystemm12"> System admin</span>
            <span>
              <IoIosArrowDown className="downArrow" />
            </span> */}
          </Col>
        </Row>
      </div>

      <div id="mobileViewHeader">
        {["start"].map((placement, idx) => (
          <MobileViewHeaderComp key={idx} placement={placement} />
        ))}
      </div>
    </Container>
  );
}

export default React.memo(Header);
