import { HttpClient } from "./HttpClient";

// const getFacultyCourses = (item) => {
//   let url = `all-course-assign-to-teacher/${item?.id}`;
//   return HttpClient.apiCaller(url, "GET", null, item?.token);
// };

// const getStackCarausel = () => {
//   let url = "get-learn-carousel";
//   return HttpClient.apiCaller(url, "GET", null);
// };

// const updateCourseDetails = (item) => {
//   let url = `course`;
//   return HttpClient.apiCaller(url, "PUT", item.data, item?.token);
// };

// const postCourseSection = (item) => {
//   let url = `add-section`;
//   return HttpClient.apiCaller(url, "POST", item.data, item?.token);
// };

// const deleteCenter = (item) => {
//   let url = `delete-center/${item?.id}`;
//   return HttpClient.deleteApicaller(url, item?.token);
// };

export const login = (data) => {
  let url = "admin/login";
  return HttpClient.apiCaller(url, "POST", data);
};

export const getOurteam = (items) => {
  console.log("getting", items);
  // let url = `v1/our-team/get?page=${items.page}&limit=10`;
  let url = `v1/our-team?page=${items.page}&limit=10`;
  return HttpClient.apiCaller(url, "GET", null, items.token);
};

export const getMaterialList = (token, page = 1, limit = 10) => {
  console.log("getting token", token);
  let url = `v1/product/get?page=${page}&limit=${limit}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postOurTeam = (item) => {
  // console.log(item, "item.......");
  let url = `v1/our-team/add`;
  return HttpClient.apiCaller(url, "POST", item, null);
};

const singleImageUpload = (data) => {
  console.log(data, "data..........");
  let url = `upload/single-upload`;
  return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
};

const multipleImageupload = (data) => {
  console.log(data, "data..........");
  let url = `upload/multiple-upload`;
  return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
};

// api/upload/csv-upload

const csvupload = (data) => {
  console.log(data, "data..........");
  let url = `upload/csv-upload`;
  return HttpClient.apiCaller(url, "PATCH", data, "", "multipart/form-data");
};

const putOurTeam = (data, id) => {
  // console.log(data, "item..............*********************************");
  let url = `v1/our-team/update/${id}`;
  // console.log(url, "url..........................");
  return HttpClient.apiCaller(url, "PUT", data);
};

const getBaseClass = (token) => {
  let url = "v1/base-class";
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postBaseClass = (data, token) => {
  let url = "v1/base-class";
  return HttpClient.apiCaller(url, "POST", data, token, null);
};

const putBaseClass = (data, id, token) => {
  let url = `v1/base-class/${id}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};
const getVenderDetails = (page, limit, isVerified, subRole, token) => {
  const url = `v1/user/vendor?page=${page}&limit=${limit}&isVerified=${isVerified}&subRole=${subRole}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const getVenderDetailsById = (id, token) => {
  const url = `v1/user/vendor/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const updateVenderId = (id, data, token) => {
  const url = `v1/user/vendor/${id}`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};

const putProductData = (id, data, token) => {
  const url = `v1/product/update/${id}`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};
const approveProduct = (id, token, approvalStatus) => {
  const url = `admin/approve-product/${id}`;
  return HttpClient.apiCaller(url, "PATCH", approvalStatus, token);
};
const getCategory = (token, type) => {
  const url = `v1/base-category/get?type=${type || "HOME"}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postCategory = (data, token) => {
  let url = `v1/base-category/add`;
  return HttpClient.apiCaller(url, "POST", data, token);
};
const putCategory = (id, data, token) => {
  let url = `v1/base-category/update/${id}`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};

const getCarousel = (token, type) => {
  let url = `admin/custom-section?type=${type}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postCarousel = (data, token) => {
  let url = `admin/custom-section`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const patchCarousel = (id, data, token) => {
  let url = `admin/custom-section/${id}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};

const getpresets = (token, type) => {
  let url = `admin/custom-section?type=${type}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postpresets = (data, token) => {
  let url = `admin/custom-section`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const getVideo = (token, type) => {
  let url = `admin/custom-section?type=${type}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const postVideo = (data, token) => {
  let url = `admin/custom-section`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const getProductData = (token) => {
  let url = `v1/project?page=1&limit=20`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const createNewFaq = (data, token) => {
  const url = `admin/faq`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const getAllFaq = (page, limit, token) => {
  const url = `admin/faq?page=${page}&limit=${limit}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const updateFaq = (id, data, token) => {
  const url = `admin/faq/${id}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};

const deleteFaq = (id, token) => {
  const url = `admin/faq/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};

const getProductById = (id, token) => {
  const url = `v1/product/get/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const createNewTestimonials = (data, token) => {
  const url = `v1/testimonial/add`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const getAllTestimonials = (token) => {
  const url = `v1/testimonial/get`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const updateTestimonials = (id, data, token) => {
  const url = `v1/testimonial/update/${id}`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};

const deleteTestimonials = (id, token) => {
  const url = `v1/testimonial/delete/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
const addSlot = (data, token) => {
  const url = `admin/slot/manage`;
  return HttpClient.apiCaller(url, "POST", data, token);
};

const getSlot = (token) => {
  const url = `admin/slot`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const confirmOrRejectProject = (data, id, token) => {
  const url = `v1/project/update-status/${id}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};

const getProjectById = (id, token) => {
  const url = `v1/project/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const updateDateWiseSlot = (data, token) => {
  const url = `admin/slot/custom-date`;
  return HttpClient.apiCaller(url, "POST", data, token);
};
const getAllUser = (token) => {
  const url = `v1/project?page=1&limit=20`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const SigleUserDetails = (id, token) => {
  const url = `v1/project/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const ProjectTeamData = (role, token) => {
  const url = `admin/sub-admin?page=1&limit=10&sub_role=${role}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const AddMember = (projectId, data, token) => {
  const url = `v1/project/assign/${projectId}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};
const AddApprovedStatus = (projectId, data, token) => {
  const url = `v1/project/admin-approval/${projectId}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};

const getConsultantByProjectId = (item) => {
  const url = `v1/project/consult/get?page=1&limit=10&projectId=${item.id}`;

  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const updateConsultantDetails = (item) => {
  const url = `v1/project/consult/update/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const updateMaterialDoc = (item) => {
  let url = `v1/project/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const updateProjectEstimate = (item) => {
  let url = `v1/project/estimate/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const updateProjectNewEstimate = (item) => {
  let url = `v1/project/update-estimation/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const getAllProjects = (token) => {
  const url = `v1/project`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getQaQcDataAPI = (data) => {
  const url = `v1/project/qa-qc/${data.projectId}`;
  return HttpClient.apiCaller(url, "GET", {}, data.token);
};
const sendQaQc = (data) => {
  const url = `v1/project/qa-qc/${data.qaqcId}`;
  return HttpClient.apiCaller(url, "PATCH", {}, data.token);
};

const getSystemAdminDashboard = (token) => {
  const url = `v1/user/statistics`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getSingleProjectDetailsAPI = (data) => {
  const url = `v1/project/${data.projectId}`;
  return HttpClient.apiCaller(url, "GET", {}, data.token);
};
const getMileStonesAPI = (data) => {
  const url = `v1/project/get-status/${data.projectIdd}`;
  return HttpClient.apiCaller(url, "GET", {}, data.token);
};
const getStatusUpdare = (projectId, token, data) => {
  const url = `v1/project/update-status/${projectId}`;
  return HttpClient.apiCaller(url, "PATCH", data, token);
};
const getAllLeads = (token) => {
  const url = `v1/lead?page=1&limit=100`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const postRoomStyle = (item) => {
  const url = `v1/bed-room-style/add`;
  return HttpClient.apiCaller(url, "POST", item.data, item.token);
};

const getRoomStyleByCategoryId = (item) => {
  console.log("category", item.id);
  let url = `v1/bed-room-style/get-by-category?page=1&limit=10&id=${item.id}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};
const getAllSalesEmployee = (token) => {
  let url = `admin/sales-employee?sub_role=SELLER`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const getPaymentStatisticPerYear = (item) => {
  let url = `v1/stats/payment-month-wise?year=${item.year}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getProjectStatisticPerYear = (item) => {
  let url = `v1/stats/project-month-wise?year=${item.year}`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const getAllSalePartnerPercentage = (item) => {
  let url = `v1/user/vendor?page=1&limit=10&isVerified=true&subRole=SELLER&commission=true`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};

const updateSalesPartnerPercentage = (item) => {
  let url = `v1/user/vendor`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const assignDmLeadsToSalesEmploye = (item) => {
  let url = `admin/sales-employee/assign/${item.id}`;
  return HttpClient.apiCaller(url, "PATCH", item.data, item.token);
};

const getReferalProjects = (item) => {
  let url = `v1/project/referrals/get-all`;
  return HttpClient.apiCaller(url, "GET", {}, item.token);
};
const getLeads = (token, year) => {
  let url = `v1/stats/lead-month-wise?year=${year}`;
  console.log("++++", year);
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const UserController = {
  login,
  getOurteam,
  postOurTeam,
  singleImageUpload,
  csvupload,
  putOurTeam,
  getMaterialList,
  getBaseClass,
  postBaseClass,
  putBaseClass,
  getVenderDetails,
  getVenderDetailsById,
  updateVenderId,
  putProductData,
  approveProduct,
  getCategory,
  postCategory,
  putCategory,
  getCarousel,
  postCarousel,
  patchCarousel,
  getpresets,
  postpresets,
  getVideo,
  postVideo,
  getProductData,
  createNewFaq,
  getAllFaq,
  updateFaq,
  deleteFaq,
  getProductById,
  createNewTestimonials,
  updateTestimonials,
  getAllTestimonials,
  deleteTestimonials,
  addSlot,
  confirmOrRejectProject,
  getProjectById,
  getSlot,
  updateDateWiseSlot,
  getAllUser,
  SigleUserDetails,
  ProjectTeamData,
  AddMember,
  AddApprovedStatus,
  getConsultantByProjectId,
  updateConsultantDetails,
  updateMaterialDoc,
  updateProjectEstimate,
  updateProjectNewEstimate,
  multipleImageupload,
  getAllProjects,
  sendQaQc,
  getQaQcDataAPI,
  getSystemAdminDashboard,
  getSingleProjectDetailsAPI,
  getMileStonesAPI,
  getStatusUpdare,
  postRoomStyle,
  getRoomStyleByCategoryId,
  // getProjectStatus,
  getAllLeads,
  getAllSalesEmployee,
  getPaymentStatisticPerYear,
  getProjectStatisticPerYear,
  getAllSalePartnerPercentage,
  updateSalesPartnerPercentage,
  assignDmLeadsToSalesEmploye,
  getReferalProjects,
  getLeads,
};
