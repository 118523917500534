export const NavConstants = {
  // Auth Navigation
  LoginPage: "/",
  Signupdetail: "/projectdetails",

  // sales admin Navigation
  ManageBidViewDetails: "/ManageBidViewDetails",
  ManageBidServicePerson: "/managebid-serviceperson",
  dashboard: "/dashboard",
  userResponse: "/user-response",
  leads: "/leads",
  BidsSupplier: "/supplierbids",
  ServiceBid: "/servicebids",
  vendoronboarding: "/vendoronboarding",
  userFeedback: "/user-feedback",
  salesEmployee: "/sales-admin-sales",

  ProjectUpdate: "/project-Update",
  ProjectUpdateManagement: "/project-Update/update-management",
  projectUpdateQaQc: "/project-Update/qa-qc",
  milestone: "/project-Update/milestone",
  financeupdates: "/project-Update/finance-updates",
  projectmanagementdashboard: "/projectmanagementdashboard",

  //system admin Navigation
  systemAdminDashboard: "/dashboard",
  systemAdmin: "",
  heroSection: "/hero-section",
  imageSection: "/image-section",
  testing: "/testing",
  projectmanager: "/projectmanager",
  projectdetails: "/projectdetails",
  ServicePersonPage: "/ServicePersonPage",
  OnboardingDetails: "/onboarding-details/:id",
  FAQs: "/faq-section",
  Testimonials: "/testimonials-section",
  SettingManageTeam: "/manage-team",
  ManagePresets: "/manage-presets",
  AdminProjectManager: "/adminprojectmanager",
  AdminSalesEmployee: "/adminsalesemployee",
  SettingVideo: "/video-section",
  ProjectManagerProject: "/projectmanagerproject",
  Category: "/category",
  Material: "/material/materialdetails",
  MaterialList: "/materiallist",
  AdminFinanace: "/adminfinance",
  MaterialInventory: "/materialinventory",
  AllVendorSales: "/allvendorsales",
  ManageAdminSales: "/manageadminsales",
  Newsletter: "/newsletter",
  AllUser: "/projects",
  bookSlot: "/book-slot",
  // Finance Admin Navigation
  financeDashboard: "/dashboard",
  transaction: "/transaction",
  accounts: "/accounts",
  paymentHistory: "/payment-history",
  ProjectPayment: "/project-payment",
  AllUserProjectDetail: "/system-admin/alluserproject-details",
  financeProjectPayment: "/project-payment",
  ProjectPaymentDetails: "/project-payment-details",
  profileEdit: "/profile-edit",
  notification: "/notification",
  roomStyle: "/room-style",
  salesPercentage: "/sales-percentage",
  referalProjects: "/referal-projects",
  qaqc:"/QA-QC",

  //QaQcDashboard
  QaQcDashboard: "/QA-QC-Dashboard",
  MyProjects: "/MyProjects",
  MyProjectDetails: "/MyProjectDetails",

  // Project manager panel
  ProjectManagerDashboard: "/dashboard",
  ManageProject: "/manage-project",
  ProjectDetails: "/project-details",

  // salesEmployee panel
  salesemployeedashboard: "/salesemployeedashboard",
  projectupdates: "/projectupdates",
  dmleads: "/digital-marketing-leads",
  // salesemployeedashboard: "/salesemployeedashboard",
  // projectupdates: "/projectupdates",
  EmployeAdminProjectDetails: "/EmployeAdminProjectDetails",
};
